import React from "react";
import { Grid, Typography, Box, ListItemText } from "@mui/material";

import algoPoapVideo from "../assets/images/algoPoapVideo.mp4";

const boxSx = {
  textAlign: "left",
  width: "70%",
  margin: "auto",
};
const listItemSx = {
  marginTop: "3%",
  fontFamily: "Roboto",
  fontWeight: "100",
  textAlign: "justify",
  display: "list-item",
};

function UseCases() {
  return (
    <Grid container sx={{'&.MuiGrid-root':{backgroundColor:'#1f1f1e'}}}>
      <Grid item xs={12} sm={6} md={6}>
        <Box component="div" sx={boxSx}>
          <Typography
            sx={{
              marginTop: "5%",
              fontFamily: "Orbitron",
              fontSize: { xs: "26px", sm: "35px" },
            }}
          >
            Use Cases
          </Typography>
          <br />
          <Grid container>
            <Grid item xs={12} sm={6} md={6}>
              <ListItemText sx={listItemSx} primary="NFT designers" />

              <ListItemText sx={listItemSx} primary="Tourism" />

              <ListItemText sx={listItemSx} primary="Music artists" />

              <ListItemText
                sx={listItemSx}
                primary="Fan clubs & social clubs"
              />

              <ListItemText sx={listItemSx} primary="DAOs" />

              <ListItemText sx={listItemSx} primary="GameFi" />

              <ListItemText sx={listItemSx} primary="Ticketing services" />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <ListItemText sx={listItemSx} primary="Law & legal entities" />

              <ListItemText sx={listItemSx} primary="Entertainment services" />

              <ListItemText sx={listItemSx} primary="Sport organizations" />

              <ListItemText
                sx={listItemSx}
                primary="Venue & event organizers"
              />

              <ListItemText
                sx={listItemSx}
                primary="Universities & educational entities"
              />

              <ListItemText sx={listItemSx} primary="Community managers" />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Box
          component="video"
          autoPlay
          loop
          muted
          src={algoPoapVideo}
          sx={{
            width: { xs: "300px", sm: "400px" },
            height: "auto",
            marginTop: "5%",
            marginBottom: "5%",
          }}
        />
      </Grid>
    </Grid>
  );
}

export default UseCases;
