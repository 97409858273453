import React, { Component } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { AutoStoriesOutlined } from "@mui/icons-material";
import logo from "../assets/images/logo.png";

const logoImgSx = {
  width: "100px",
  cursor: "pointer",
  animation: "animateLogo 4s linear infinite",
};
const titleSx = {
  "&.MuiTypography-root": {
    fontWeight: "bold",
    fontFamily: "Orbitron,Roboto",
    animation: "animateTitle 4s linear infinite",
  },
};
const appbarSx = {
  "&.MuiAppBar-root": {
    backgroundColor: "#1b1c1b",
    color: "#fff",
  },
};
const iconBtnSx = {
  color: "#76f935",
  "&:hover": {
    color: "#fff",
  },
};

function Header(props) {
  return (
    <>
      <AppBar position="static" elevation={0} sx={appbarSx}>
        <Toolbar>
          <Box component="img" src={logo} sx={logoImgSx} alt="AlgoPOAP" />
          <Typography variant="h5" className="title" sx={titleSx}>
            AlgoPOAP
          </Typography>

          <Box sx={{ flexGrow: 1 }} />

          <Tooltip title="Documents" placement="bottom">
            <IconButton
              target="_blank"
              href="  https://algopoap.gitbook.io/algopoap/"
              sx={iconBtnSx}
            >
              <AutoStoriesOutlined fontSize="large" />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;
