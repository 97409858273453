import React from "react";
/** @jsxImportSource @emotion/react */

import { Grid, Typography, Box } from "@mui/material";
const titleSx = {
  marginBottom: "20px",
  marginTop: "5%",
  fontFamily: "Orbitron",
  color: "#fff",
  fontSize: { xs: "26px", sm: "35px" },
};

class Features extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Grid item xs={12} sm={12} md={12} sx={{ padding: "10px" }}>
        <Typography variant="h3" sx={titleSx}>
          Features
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="box">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Orbitron" }}>
                  Designed for Web 3.0
                </Typography>
                <p> Permission-less, decentralized and trustless</p>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="box">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Orbitron" }}>
                  Smart NFTs
                </Typography>
                <p>
                  {" "}
                  Each AlgoPOAP has its own smart ARC3/19/69 NFT collection.
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="box">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Orbitron" }}>
                  Multi & Single MagicLinks
                </Typography>
                <p>
                  {" "}
                  Prepaid and MBR ready magic links and QRCodes to distribute
                  for claim.
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="box">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Orbitron" }}>
                  BYON
                </Typography>
                <p> Bring your own NFT, ASA or an array of them.</p>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="box">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Orbitron" }}>
                  Time Constraint
                </Typography>
                <p>
                  {" "}
                  All issued POAPs have an start date to bound claims to be
                  eligible only after.
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="box">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Orbitron" }}>
                  Geo Constraint
                </Typography>
                <p>
                  {" "}
                  Optional constraint to limit claim to a distance radius around
                  a longitude / latitude location point.
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="box">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Orbitron" }}>
                  Release Constraint
                </Typography>
                <p>
                  {" "}
                  Optional parameter to demand author's signature for AlgoPOAP
                  audience users to claim.
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="box">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Orbitron" }}>
                  Shared Secret Constraint
                </Typography>
                <p>
                  {" "}
                  Optional parameter to provide all in one or individually
                  crafted , cryptographically verifiable, shared secrets issued
                  as a QRCode.
                </p>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default Features;
