import React from "react";
import { Grid, Typography, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ThumbUpOutlined,
  Event,
  HandshakeOutlined,
  RemoveRedEyeOutlined,
  PersonOutlineOutlined,
  WorkspacePremiumOutlined,
} from "@mui/icons-material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#1f1f1f",
  padding: "10px",
  color: "#ffffff",
}));
function Stats() {
  return (
    <Grid
      container
      spacing={1}
      sx={{ marginTop: "10px", marginBottom: "30px" }}
      justifyContent="center"
    >
      <Grid item xs={6} sm={2} md={2}>
        <Item>
          <Grid container>
            <Grid item xs={4} sm={4} md={4}>
              <WorkspacePremiumOutlined
                sx={{ fontSize: "44px", color: "#e4e4e4", marginTop: "5px" }}
              />
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
           
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "orbitron",
                  color: "#76F935",
                  fontSize: "22px",
                  fontWeight: "bold",
                }}
              >
                5400
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontFamily: "orbitron", fontSize: "13px",color: "#e4e4e4", }}
              >
                ALGOPOAPS
              </Typography>
            </Grid>
          </Grid>
        </Item>
      </Grid>
      <Grid item xs={6} sm={2} md={2}>
        <Item>
          <Grid container>
            <Grid item xs={4} sm={4} md={4}>
              <PersonOutlineOutlined
                sx={{ fontSize: "44px", color: "#e4e4e4", marginTop: "5px" }}
              />
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "orbitron",
                  color: "#76F935",
                  fontSize: "23px",
                  fontWeight: "bold",
                }}
              >
                700
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontFamily: "orbitron", fontSize: "13px" ,color:'#e4e4e4'}}
              >
                AUTHORS
              </Typography>
            </Grid>
          </Grid>
        </Item>
      </Grid>
      <Grid item xs={6} sm={2} md={2}>
        <Item>
          <Grid container>
            <Grid item xs={4} sm={4} md={4}>
              <RemoveRedEyeOutlined
                sx={{ fontSize: "44px", color: "#e4e4e4", marginTop: "5px" }}
              />
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
          
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "orbitron",
                  color: "#76F935",
                  fontSize: "22px",
                  fontWeight: "bold",
                }}
              >
                22K+
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontFamily: "orbitron", fontSize: "13px",color: "#e4e4e4", }}
              >
                VISITORS
              </Typography>
            </Grid>
          </Grid>
        </Item>
      </Grid>
      <Grid item xs={6} sm={2} md={2}>
        <Item>
          <Grid container>
            <Grid item xs={4} sm={4} md={4}>
              <ThumbUpOutlined
                sx={{ fontSize: "44px", color: "#e4e4e4", marginTop: "5px" }}
              />
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
             
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "orbitron",
                  color: "#76F935",
                  fontSize: "22px",
                  fontWeight: "bold",
                }}
              >
                25K+
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontFamily: "orbitron", fontSize: "13px",color: "#e4e4e4", }}
              >
                ENGAGMENT
              </Typography>
            </Grid>
          </Grid>
        </Item>
      </Grid>
      <Grid item xs={6} sm={2} md={2}>
        <Item>
          <Grid container>
            <Grid item xs={4} sm={4} md={4}>
              <HandshakeOutlined
                sx={{ fontSize: "44px", color: "#e4e4e4", marginTop: "5px" }}
              />
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
             
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "orbitron",
                  color: "#76F935",
                  fontSize: "22px",
                  fontWeight: "bold",
                }}
              >
                8
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontFamily: "orbitron", fontSize: "13px",color: "#e4e4e4", }}
              >
                PARTNERS
              </Typography>
            </Grid>
          </Grid>
        </Item>
      </Grid>
      <Grid item xs={6} sm={2} md={2}>
        <Item>
          <Grid container>
            <Grid item xs={4} sm={4} md={4}>
              <Event
                sx={{ fontSize: "44px", color: "#e4e4e4", marginTop: "5px", }}
              />
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
             
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "orbitron",
                  color: "#76F935",
                  fontSize: "22px",
                  fontWeight: "bold",
                }}
              >
                50+
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontFamily: "orbitron", fontSize: "13px",color: "#e4e4e4", }}
              >
                EVENTS
              </Typography>
            </Grid>
          </Grid>
        </Item>
      </Grid>
    </Grid>
  );
}

export default Stats;
