import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import algorandtimesquarevideo from "../assets/images/algopoap_vid00_min.mp4";
import algorandImage from "../assets/images/algorandImage.jpeg";
import "../assets/styles/style.css";
import { GitHub } from "@mui/icons-material";

import { Grid, Typography, Box } from "@mui/material";

const styles = {
  videoDiv: `
    height:720px;
    width:100vw;
    position: relative;
    overflow:hidden;
    background-image: url(${algorandImage});
    `,
  video: `
    width: 100vw;
    height:100%;
    position: absolute;
    object-fit: cover;
    z-index: 2;
    transform: translateX(-50%) translateY(0%);
      `,
  videoCaption: `
    z-index: 3;
    position: relative;
    text-align: left;
    color: #ffffff;
    padding-top: 3%;
    padding-left: 120px;
    `,
  text: `
    padding-left: 30px`,
  button: `
  margin-top:30px; 
       `,
};

function Banner(props) {
  return (
    <Grid item xs={12} sm={12} md={12}>
      <Box
        css={css`
          ${styles.videoDiv}
        `}
        sx={{
          height: {
            xl: "720px",
            lg: "700px",
            md: "630px",
            sm: "550px",
            xs: "430px",
          },
        }}
      >
        <video
          autoPlay
          muted
          loop
          css={css`
            ${styles.video}
          `}
        >
          <source src={algorandtimesquarevideo} type="video/mp4" />
        </video>

        <Box
          css={css`
            ${styles.videoCaption}
          `}
          sx={{
            paddingLeft: {
              xl: "135px",
              lg: "130px",
              md: "120px",
              sm: "100px",
              xs: "10px",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Orbitron",
              fontSize: {
                xs: "32px",
                sm: "60px",
                md: "80px",
                lg: "80px",
                xl: "80px",
              },
            }}
            css={css`
              ${styles.text}
            `}
          >
            Proof Of{" "}
          </Typography>

          <div className="glitch-wrapper">
            <Typography
              variant="h1"
              sx={{
                fontFamily: "Orbitron",
                fontSize: {
                  xs: "32px",
                  sm: "60px",
                  md: "80px",
                  lg: "80px",
                  xl: "80px",
                },
              }}
              className="glitch"
            >
              Anything
            </Typography>
          </div>

          <Typography
            sx={{
              fontFamily: "Orbitron",
              fontSize: {
                xs: "32px",
                sm: "60px",
                md: "80px",
                lg: "80px",
                xl: "80px",
              },
            }}
            css={css`
              ${styles.text}
            `}
          >
            Protocol
          </Typography>
          <Typography
            sx={{
              fontFamily: "Orbitron",
              fontSize: {
                xs: "32px",
                sm: "60px",
                md: "80px",
                lg: "80px",
                xl: "80px",
              },
            }}
            css={css`
              ${styles.text}
            `}
          >
            on Algorand
          </Typography>
          <Box sx={{ display: { md: "flex" } }}>
            <Box
              component="a"
              sx={{
                float: "right",
                color: "#fff",
                textDecoration: "none",
                fontSize: { sm: "12px" },
              }}
            ></Box>

            <Box
              component="a"
              className="animated-button"
              href="https://testnet.algopoap.xyz"
              target="_blank"
              sx={{
                float: "left",
                marginLeft: "25px",
                marginTop: "25px",
                textDecoration: "none",
              }}
            >
              <Box component="span"></Box>
              <Box component="span"></Box>
              <Box component="span"></Box>
              <Box component="span"></Box>
              <Typography
                sx={{
                  textDecoration: "none",
                  fontSize: { sm: "12px" },
                  fontFamily: "Orbitron",
                }}
              >
                Launch  
                <Box component="text" sx={{color:'yellow'}}> TESTNET </Box>
                dApp
              </Typography>
            </Box>

            <Box
              component="a"
              className="animated-button"
              href="https://algopoap.xyz"
              target="_blank"
              sx={{
                float: "left",
                marginLeft: "25px",
                marginTop: "25px",
              }}
            >
              <Box component="span"></Box>
              <Box component="span"></Box>
              <Box component="span"></Box>

              <Typography
                sx={{
                  textDecoration: "none",
                  fontSize: { sm: "12px" },
                  fontFamily: "Orbitron",
                }}
              >
                Launch 
                <Box component="text" sx={{color:'yellow'}}> MAINNET </Box>
                 dApp
              </Typography>
            </Box>
          </Box>

         
        </Box>
      </Box>
    </Grid>
  );
}

Banner.propTypes = {
  featuresRef: PropTypes.func,
};
export default Banner;
