import React from "react";
import algorandVideo from "../assets/images/algorandtimesquarevideo.mp4";
import { Grid, Typography, Box } from "@mui/material";
import algorandImage from "../assets/images/algorandImage.jpeg";

const titleSx = {
  textAlign: "left",
  width: "70%",
  margin: "auto",
};

function About() {
  return (
    <Grid container sx={{'&.MuiGrid-root':{backgroundColor:'#1f1f1e'}}}>
      <Grid item xs={12} sm={6} md={6} order={{ xs:2, sm: 1 }}>
        <Box
          sx={{
            marginTop: "5%",
          }}
        >
       
          <Box component='img' src={algorandImage} alt='Algorand'  sx={{
            width: { xs: "350px", sm: "500px" },
            height: "300px",
            marginTop: "1%",
            marginBottom: "5%",
          }} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6} order={{ xs:1, sm: 2 }}>
        <Box component="div" sx={titleSx}>
          <Typography
            sx={{
              marginTop: { xs: "20%", sm: "5%" },
              fontFamily: "Orbitron",
              fontSize: { xs: "28px", sm: "35px" },
            }}
          >
            Why Algorand?
          </Typography>
          <br />
          <Typography
            variant="h6"
            sx={{
              marginTop: "3%",
              textAlign: "justify",
              fontFamily: "Roboto",
              fontWeight: "100",
            }}
          >
            Algorand is a high tech green blockchain with an environmental
            impact focus from the very beginning. Algorand's founder Silvio
            Micali explained as far back as 2018 his focus on sustainability for
            our planet with next generation technology. With his unique
            consensus mechanism, Algorand is far more energy efficient than
            other blockchains, and is going further by offsetting its small
            carbon footprint in partnership with ClimateTrade.
          </Typography>
          <br />
          <Box
            component="a"
            sx={{
              color: "#76f935",
              textDecoration: "none",
              marginBottom: { xs: "10%", sm: "0px" },
            }}
            href="https://www.algorand.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read more on Algorand's website
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default About;
