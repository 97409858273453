import React from "react";
import Banner from "./Banner";
import Features from "./Features";
import About from "./About";
import Details from "./Details";
import Marquee from "react-fast-marquee";

import { Box } from "@mui/material";

import algorandLogo from "../assets/images/algorand_logo.svg";
import foundationImg from "../assets/images/foundation_logo.svg";
import axlLogo from "../assets/images/axl_logo.svg";
import algoHubLogo from "../assets/images/algohub_logo.png";
import waivlengthLogo from "../assets/images/waivlength.jpg";
import algogatorLogo from "../assets/images/algogator_logo.svg";
import bordlessCapitalLogo from "../assets/images/borderless_logo.png";
import goanaLogo from "../assets/images/goanna_logo.png";
import Timeline from "./Timeline";
import UseCases from "./UseCases";
import Benefits from "./Benefits";
import Stats from "./Stats";
import TabsPanel from "./TabsPanel";

const boxSx = {
  backgroundColor: "#1b1c1b",
  color: "#fff",
};


function LandingPage() {
  return (
    <>
      <Box component="div" sx={{ overflowX: "hidden" }}>
        <Box sx={boxSx}>
          <Banner />
          <Stats />
          <Details />
          <Features />
          <UseCases />
          {/* <Benefits /> */}
          {/* <TabsPanel /> */}
          {/* <Timeline /> */}
          
          <Box component="div" sx={{ paddingTop: "30px",paddingBottom:'30px', }}>
            <Marquee>
              <Box
                component="img"
                src={algorandLogo}
                sx={{
                  width: "80px",
                  height: "80px",
                  marginRight: "50px",
                  marginLeft: "50px",
                }}
              />
              <Box
                component="img"
                src={foundationImg}
                sx={{
                  width: "85px",
                  height: "85px",
                  marginRight: "50px",
                  marginLeft: "50px",
                }}
              />
              <Box
                component="img"
                src={waivlengthLogo}
                sx={{
                  width: "65px",
                  height: "65px",
                  marginRight: "50px",
                  marginLeft: "50px",
                }}
              />
              <Box
                component="img"
                src={bordlessCapitalLogo}
                sx={{
                  width: "50px",
                  height: "50px",
                  marginRight: "50px",
                  marginLeft: "50px",
                  marginTop: "15px",
                }}
              />
              <Box
                component="img"
                src={axlLogo}
                sx={{
                  width: "80px",
                  height: "80px",
                  marginRight: "50px",
                  marginLeft: "50px",
                }}
              />
              <Box
                component="img"
                src={goanaLogo}
                sx={{
                  width: "110px",
                  height: "75px",
                  marginRight: "50px",
                  marginLeft: "50px",
                }}
              />
              <Box
                component="img"
                src={algoHubLogo}
                sx={{
                  width: "80px",
                  height: "80px",
                  marginRight: "50px",
                  marginLeft: "50px",
                }}
              />


              <Box
                component="img"
                src={algogatorLogo}
                sx={{
                  width: "50px",
                  height: "80px",
                  marginRight: "50px",
                  marginLeft: "50px",
                  marginTop: "5px",
                }}
              />

            </Marquee>
          </Box>
          <About />
        </Box>
      </Box>
    </>
  );
}

export default LandingPage;
