import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import detailsImg from "../assets/images/details.png";

const boxSx = {
  textAlign: "left",
  width: "70%",
  margin: "auto",
};

const titleSx = {
  marginTop: "5%",
  fontFamily: "Orbitron",
  color: "#fff",
  fontSize: { xs: "26px", sm: "35px" },
};

const titleScrollSx = {
  marginTop: "5%",
  fontFamily: "Orbitron",
  color: "#fff",
  fontSize: { xs: "26px", sm: "35px" },
  animation: "moveToLeft 3s",
};

const imageSx={
  width: "400px",
  height: "auto",
  marginTop: "5%",
  marginLeft: {xs:'0%',sm:"6%"},
}
const imageScrollSx={
  width: "400px",
  height: "auto",
  marginTop: "5%",
  marginLeft: {xs:'0%',sm:"6%"},
  animation: "moveToRight 2s",
}

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    return (
      <Grid container sx={{ backgroundColor: "#1f1f1e" }}>
        <Grid item xs={12} sm={6} md={6} order={{ xs: 2, sm: 1 }}>
          <Box
            component="img"
            src={detailsImg}
            sx={ imageSx}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} order={{ xs: 1, sm: 2 }}>
          <Box component="div" sx={boxSx}>
            <Typography sx={titleSx}>
              What is AlgoPOAP?
            </Typography>
            <br />
            <Typography
              variant="h6"
              sx={{
                marginTop: "3%",
                fontFamily: "Roboto",
                fontWeight: "100",
                textAlign: "justify",
              }}
            >
              AlgoPOAP is the Proof of Anything Protocol on Algorand
              blockchain!
              <br />
              being Token-less, dynamic
              and completely permission-less as well as many more control
              dimensions provided by AlgoPOAP makes it completely unique!
              AlgoPOAP is packed with many
              features out of the box to help creators, developers , owners and
              users in general to distribute proofs, tokens and NFTs in a Web
              2.5 way!
              <br />
              AlgoPOAP will use Algorand State Proofs to go multi-chain and will
              be the first of its kind to do so! A multi-chain Proof of Anything
              Protocol!
            </Typography>
            <br />
          </Box>
          <br />
        </Grid>
      </Grid>
    );
  }
}

export default Details;
