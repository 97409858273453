import React from "react";
/** @jsxImportSource @emotion/react */
import LandingPage from "./LandingPage";
import Header from "./Header";
import Footer from "./Footer";
import { Grid } from "@mui/material";

function MainPage() {
  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} sm={12} md={12}>
        <Header />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <LandingPage />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Footer />
      </Grid>
    </Grid>
  );
}

// MainPage.propTypes = {};
export default MainPage;
